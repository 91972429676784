// import * as UserApi from '../api/user';

import { toastr } from 'react-redux-toastr';
import { Dispatch } from 'redux';
import User from '../../models/user';
import { CustomerCart } from '../../models/customer';
import api from '../../api';
import { types as commonTypes } from './common';

// action types
export const types = {
  // login
  LOGIN_REQUEST: 'AUTH/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH/LOGIN_SUCCESS',
  LOGIN_ERROR: 'AUTH/LOGIN_ERROR',

  // password
  PASSWORD_REQUEST: 'AUTH/PASSWORD_REQUEST',
  PASSWORD_SUCCESS: 'AUTH/PASSWORD_SUCCESS',
  PASSWORD_ERROR: 'AUTH/PASSWORD_ERROR',

  // otp
  OTP_REQUEST: 'AUTH/OTP_REQUEST',
  OTP_SUCCESS: 'AUTH/OTP_SUCCESS',
  TERMS_SUCCESS: 'AUTH/TERMS_SUCCESS',
  OTP_ERROR: 'AUTH/OTP_ERROR',

  // otp
  RESEND_OTP_REQUEST: 'AUTH/RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'AUTH/RESEND_OTP_SUCCESS',
  RESEND_OTP_ERROR: 'AUTH/RESEND_OTP_ERROR',

  // logout
  LOGOUT: 'AUTH/LOGOUT',
  // redirect
  REDIRECT_SAVE: 'AUTH/REDIRECT_SAVE',
  REDIRECT_APPLY: 'AUTH/REDIRECT_APPLY',

  // forgot password
  FORGOT_PASSWORD_REQUEST: 'AUTH/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'AUTH/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'AUTH/FORGOT_PASSWORD_ERROR',

  // change password
  CHANGE_PASSWORD_REQUEST: 'AUTH/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'AUTH/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'AUTH/CHANGE_PASSWORD_ERROR',

  // User profile
  FETCH_PROFILE_REQUEST: 'USER/FETCH_PROFILE_REQUEST',
  FETCH_PROFILE_SUCCESS: 'USER/FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_ERROR: 'USER/FETCH_PROFILE_ERROR',

  CHANGE_USER: 'USER/CHANGE_USER',
  SWiICH_USER: 'USER/SWiICH_USER',
};

export interface AuthState {
  isLoading: boolean;
  authToken?: string;
  redirectTo: string;
  countryCode?: string;
  dialCode?: string;
  phoneNumber?: string;
  loginError?: string;
  otpError?: string;
  verified?: boolean;
  forgotPasswordError?: string;
  changePasswordError?: string;
  userLogin?: string;
  userToken?: string;
  privileges?: any;
  userType?: string;
  acceptTerms?: string;
  user?: User;
  isUserLoaded: boolean;
  isUserLoading: boolean;
  customerToken?: boolean;
  customerSelected?: any;
}

interface ForgotPasswordState {
  id?: string;
  error?: string;
}

// initial state
const initialState: AuthState = {
  isLoading: false,
  redirectTo: '/',
  isUserLoading: false,
  isUserLoaded: false,
};

// reducer
export default (state: AuthState = initialState, action: any): AuthState => {
  switch (action.type) {
    // login
    case types.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
        otpError: undefined,
      };
    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.data.userToken);

      return {
        ...state,
        isLoading: false,
        authToken: action.data.userToken,
        privileges: action.data ? action.data.privileges : undefined,
        userType: action.data ? action.data.userType : undefined,
        loginError: undefined,
        otpError: undefined,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        otpError: action.data ? action.data.otpErrorMessage : undefined,
      };

    case types.PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
      };
    case types.PASSWORD_SUCCESS:
      localStorage.setItem('token', action.data.userToken);
      return {
        ...state,
        isLoading: false,
        authToken: action.data.userToken,
        loginError: undefined,
      };
    case types.PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
      };
    // login
    case types.OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        loginError: undefined,
        otpError: undefined,
      };
    case types.OTP_SUCCESS:
      localStorage.setItem(
        'token',
        action.passwordExists ? action.data : undefined
      );
      return {
        ...state,
        isLoading: false,
        authToken: action.passwordExists ? action.data : undefined,
        verified: true,
        loginError: undefined,
        otpError: undefined,
      };
    case types.OTP_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        loginError: action.data ? action.data.errorMessage : undefined,
        otpError: action.data ? action.data.otpErrorMessage : undefined,
      };
    case types.TERMS_SUCCESS:
      localStorage.setItem(
        'token',
        action.data ? action.data.userToken : undefined
      );
      return {
        ...state,
        isLoading: false,
        authToken: action.data ? action.data.userToken : undefined,
        privileges: action.data ? action.data.privileges : undefined,
        userType: action.data ? action.data.userType : undefined,
        verified: true,
        loginError: undefined,
        otpError: undefined,
      };
    // logout
    case types.LOGOUT:
      api.setToken(undefined);
      api.setCustomerToken(undefined);
      localStorage.removeItem('authUser');
      localStorage.removeItem('cart');
      localStorage.removeItem('token');
      localStorage.clear();
      return {
        ...state,
        authToken: undefined,
        privileges: undefined,
        userToken: undefined,
        userLogin: undefined,
        userType: undefined,
        customerToken: undefined,
        customerSelected: undefined,
      };
    case commonTypes.RESET_DATA:
      return {
        ...initialState,
        countryCode: state.countryCode,
        dialCode: state.dialCode,
        phoneNumber: state.phoneNumber,
      };

    case types.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        changePasswordError: undefined,
        forgotPasswordError: undefined,
      };
    case types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userLogin: undefined,
        userToken: undefined,
        changePasswordError: undefined,
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        changePasswordError: action.data ? action.data.errorMessage : undefined,
      };

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPasswordError: undefined,
        changePasswordError: undefined,
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userLogin: action.userLogin,
        userToken: action.data.userToken,
        forgotPasswordError: undefined,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isLoading: false,
        authToken: undefined,
        forgotPasswordError: action.data ? action.data.errorMessage : undefined,
      };

    case types.RESEND_OTP_REQUEST:
      return {
        ...state,
        isLoading: true,
        forgotPasswordError: undefined,
        changePasswordError: undefined,
      };
    case types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.RESEND_OTP_ERROR:
      return {
        ...state,
        isLoading: false,
      };

    // profile
    case types.FETCH_PROFILE_REQUEST:
      return { ...state, isUserLoading: true };
    case types.FETCH_PROFILE_SUCCESS:
      const obj = {
        ...state,
        isUserLoading: false,
        user: action.data ? action.data.result : null,
        isUserLoaded: !!action.data,
      };
      if (!obj.user) {
        obj.authToken = undefined;
        obj.privileges = undefined;
        obj.userToken = undefined;
        obj.userLogin = undefined;
        obj.userType = undefined;
      }
      return obj;
    case types.FETCH_PROFILE_ERROR:
      return { ...state, isUserLoading: false };

    // redirect
    case types.REDIRECT_SAVE:
      return { ...state, redirectTo: action.data };
    case types.REDIRECT_APPLY:
      return { ...state, redirectTo: '/' };

    case types.CHANGE_USER:
      return {
        ...state,
        customerToken: action.data ? action.data.customerToken : undefined,
        customerSelected: action.data,
      };
    case types.SWiICH_USER:
      return {
        ...state,
        customerToken: action.data ? action.data.customerToken : undefined,
        customerSelected: action.data,
      };
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  login: (
    userLogin: string,
    onSuccess?: () => void,
    onError?: (er: string) => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      const response = await api.user.login(userLogin);
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        dispatch({
          type: types.LOGIN_ERROR,
          data: { errorMessage: data.errorMsg },
        });
        if (onError) onError(data.errorMsg);
      } else {
        dispatch({ type: types.LOGIN_SUCCESS, data: response.data.result });

        api.setToken(response.data.result.userToken);

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
        data: {
          errorMessage: error?.response?.data?.errorMsg || 'Invalid Code',
        },
      });
      if (onError) onError(error?.response?.data?.errorMsg || 'Invalid Code');
    }
  },

  password: (
    password: string,
    userToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.LOGIN_REQUEST,
    });
    try {
      const response = await api.user.password(password, userToken);
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.LOGIN_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({ type: types.LOGIN_SUCCESS, data: response.data.result });
        if (
          response.data.result.verified &&
          response.data.result.acceptTerms === 'Yes'
        ) {
          api.setToken(response.data.result.userToken);
        }

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.LOGIN_ERROR,
        data: { errorMessage: 'Invalid Password!' },
      });
    }
  },

  submitOtp: (
    otp: string,
    userToken?: string,
    passwordExists?: boolean,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.OTP_REQUEST,
    });
    try {
      const response = await api.user.submitOtp(otp, userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.OTP_ERROR,
          data: { otpErrorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.OTP_SUCCESS,
          data: userToken,
          passwordExists,
        });
        if (passwordExists) {
          api.setToken(userToken);
        }

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.OTP_ERROR,
        data: { otpErrorMessage: 'Invalid Otp!' },
      });
    }
  },

  acceptTermsofUse: (userToken?: string, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.OTP_REQUEST,
    });
    try {
      const response = await api.user.acceptTerms(userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.OTP_ERROR,
          data: { otpErrorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.TERMS_SUCCESS,
          data: data.result,
        });

        api.setToken(userToken);

        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.OTP_ERROR,
        data: { otpErrorMessage: 'Invalid Otp!' },
      });
    }
  },

  forgotPassword: (userLogin: string, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    dispatch({
      type: types.FORGOT_PASSWORD_REQUEST,
    });
    try {
      const response = await api.user.forgotPassword(userLogin);
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.FORGOT_PASSWORD_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.FORGOT_PASSWORD_SUCCESS,
          data: response.data.result,
          userLogin,
        });
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.FORGOT_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid User email or Mobile number' },
      });
    }
  },

  changePassword: (
    otp: string,
    newPassword: string,
    userToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.CHANGE_PASSWORD_REQUEST,
    });
    try {
      const response = await api.user.changePassword(
        otp,
        newPassword,
        userToken
      );
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.CHANGE_PASSWORD_ERROR,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
          data: response.data.result,
        });
        toastr.success('Success', 'Password changed successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.CHANGE_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid Password' },
      });
    }
  },

  resendOTP: (
    userLogin: string,
    userToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({
      type: types.RESEND_OTP_REQUEST,
    });
    try {
      const response = await api.user.resendOTP(userLogin, userToken);
      const { data } = response;
      if (data.response === 'Failure') {
        // toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.RESEND_OTP_SUCCESS,
          data: { errorMessage: data.errorMsg },
        });
      } else {
        dispatch({
          type: types.RESEND_OTP_ERROR,
          data: response.data.result,
        });
        toastr.success('Success', 'OTP send successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.CHANGE_PASSWORD_ERROR,
        data: { errorMessage: 'Invalid Request' },
      });
    }
  },

  fetchProfile: (onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();
    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    try {
      const response = await api.user.getStudentProfile();
      const { data } = response;
      if (data.response === 'Failure' || data.error === 1) {
        api.setToken(undefined);
      }
      dispatch({ type: types.FETCH_PROFILE_SUCCESS, data });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch({ type: types.FETCH_PROFILE_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  logout: () => async (dispatch: Dispatch) => {
    api.setToken(undefined);
    api.setCustomerToken(undefined);
    dispatch({ type: types.LOGOUT });
  },

  redirectSave: (to: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.REDIRECT_SAVE, data: to });
  },

  redirectApply: () => (dispatch: Dispatch) =>
    dispatch({ type: types.REDIRECT_APPLY }),

  changeCustomer: (customer?: CustomerCart, onSuccess?: () => void) => async (
    dispatch: Dispatch
  ) => {
    api.setCustomerToken(customer ? customer.customerToken : undefined);
    dispatch({ type: types.CHANGE_USER, data: customer });
    if (onSuccess) {
      onSuccess();
    }
  },
  switchCustomer: (
    customer?: any,
    customerToken?: string,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    api.setCustomerToken(customer ? customerToken : undefined);
    dispatch({ type: types.SWiICH_USER, data: { ...customer, customerToken } });
    if (onSuccess) {
      onSuccess();
    }
  },
};
