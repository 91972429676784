import { Dispatch } from 'redux';
import { toastr } from 'react-redux-toastr';
import {
  Order,
  StatusList,
  OrderFilter,
  OrderConfirm,
} from '../../models/order';
import api from '../../api';
import { tr } from 'date-fns/locale';

export const types = {
  RESET_DATA: 'CART/RESET_DATA',

  ORDER_STATUS_REQUEST: 'CART/ORDER_STATUS_REQUEST',
  ORDER_STATUS_SUCCESS: 'CART/ORDER_STATUS_SUCCESS',
  ORDER_STATUS_ERROR: 'CART/ORDER_STATUS_ERROR',

  GET_ORDER_REQUEST: 'CART/GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'CART/GET_ORDER_SUCCESS',
  GET_ORDER_ERROR: 'CART/GET_ORDER_ERROR',

  GET_ORDER_DETAILS_REQUEST: 'CART/GET_ORDER_DETAILS_REQUEST',
  GET_ORDER_DETAILS_SUCCESS: 'CART/GET_ORDER_DETAILS_SUCCESS',
  GET_ORDER_DETAILS_ERROR: 'CART/GET_ORDER_DETAILS_ERROR',

  ORDER_UPDATE_REQUEST: 'CART/ORDER_UPDATE_REQUEST',
  ORDER_UPDATE_SUCCESS: 'CART/ORDER_UPDATE_SUCCESS',
  ORDER_UPDATE_ERROR: 'CART/ORDER_UPDATE_ERROR',

  HIGHT_ORDER_REQUEST: 'CART/HIGHT_ORDER_REQUEST',
  HIGHT_ORDER_SUCCESS: 'CART/HIGHT_ORDER_SUCCESS',
  HIGHT_ORDER_ERROR: 'CART/HIGHT_ORDER_ERROR',

  ORDER_FILTER_REQUEST: 'CART/ORDER_FILTER_REQUEST',
  ORDER_FILTER_SUCCESS: 'CART/ORDER_FILTER_SUCCESS',
  ORDER_FILTER_ERROR: 'CART/ORDER_FILTER_ERROR',

  DOWNLOAD_ORDER_DETAILS_REQUEST: 'ORDER/DOWNLOAD_ORDER_DETAILS_REQUEST',
  DOWNLOAD_ORDER_DETAILS_SUCCESS: 'ORDER/DOWNLOAD_ORDER_DETAILS_SUCCESS',
  DOWNLOAD_ORDER_DETAILS_ERROR: 'ORDER/DOWNLOAD_ORDER_DETAILS_ERROR',

  ORDER_CONFIRM_REQUEST: 'CART/ORDER_CONFIRM_REQUEST',
  ORDER_CONFIRM_SUCCESS: 'CART/ORDER_CONFIRM_SUCCESS',
  ORDER_CONFIRM_ERROR: 'CART/ORDER_CONFIRM_ERROR',
};

export interface OrderState {
  isLoading: boolean;
  isLoaded: boolean;
  isOrderLoading: boolean;
  isOrderLoaded: boolean;
  orderList?: Order[];
  error?: string;
  statusList?: StatusList[];
  isCountLoading: boolean;
  isCountLoaded: boolean;
  totalCount?: number;
  perPageCount?: number;
  orderDetail?: Order;
  orderFilter?: OrderFilter;
  isFilterLoading: boolean;
  isFilterLoaded: boolean;
  orderFile?: string;
}

const initialState: OrderState = {
  isLoading: false,
  isLoaded: false,
  isCountLoading: false,
  isCountLoaded: false,
  isOrderLoading: false,
  isOrderLoaded: false,
  isFilterLoading: false,
  isFilterLoaded: false,
};

export default (state: OrderState = initialState, action: any): OrderState => {
  switch (action.type) {
    case types.RESET_DATA:
      return {
        ...initialState,
      };
    case types.ORDER_STATUS_REQUEST:
      return {
        ...state,
        error: undefined,
        isCountLoading: true,
        isCountLoaded: false,
      };
    case types.ORDER_STATUS_SUCCESS:
      return {
        ...state,
        statusList: action.data ? action.data.result : null,
        error: undefined,
        isCountLoading: false,
        isCountLoaded: true,
      };
    case types.ORDER_STATUS_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isCountLoading: false,
        isCountLoaded: true,
      };
    case types.GET_ORDER_REQUEST:
      return {
        ...state,
        error: undefined,
        isOrderLoading: true,
        isOrderLoaded: false,
      };
    case types.GET_ORDER_SUCCESS:
      const orderList =
        Number(action.page) <= 1
          ? [...action.data.result]
          : [
              ...(state.orderList ? state.orderList : []),
              ...action.data.result,
            ];

      return {
        ...state,
        orderList,
        error: undefined,
        isOrderLoading: false,
        isOrderLoaded: true,
        totalCount: action.data ? action.data.totalCount : 1,
        perPageCount: 10,
      };
    case types.GET_ORDER_ERROR:
      return {
        ...state,
        isOrderLoading: false,
        isOrderLoaded: true,
        totalCount: 0,
      };
    case types.GET_ORDER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case types.GET_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderDetail:
          action.data && action.data.result && action.data.result.length > 0
            ? action.data.result[0]
            : null,
      };
    case types.GET_ORDER_DETAILS_ERROR:
      return {
        ...state,
      };
    case types.ORDER_UPDATE_REQUEST:
      return {
        ...state,
        error: undefined,
        isLoading: true,
        isLoaded: false,
      };
    case types.ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        orderList: action.data ? action.data.result : null,
        error: undefined,
        isLoading: false,
        isLoaded: true,
      };
    case types.ORDER_UPDATE_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isLoading: false,
        isLoaded: true,
      };
    case types.ORDER_FILTER_REQUEST:
      return {
        ...state,
        error: undefined,
        isFilterLoading: true,
        isFilterLoaded: false,
      };
    case types.ORDER_FILTER_SUCCESS:
      return {
        ...state,
        orderFilter:
          action.data && action.data.result ? action.data.result : null,
        error: undefined,
        isFilterLoading: false,
        isFilterLoaded: true,
      };
    case types.ORDER_FILTER_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
        isFilterLoading: false,
        isFilterLoaded: true,
      };
    case types.DOWNLOAD_ORDER_DETAILS_REQUEST:
      return {
        ...state,
      };
    case types.DOWNLOAD_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        orderFile: action.data ? action.data.orderfile : null,
      };
    case types.DOWNLOAD_ORDER_DETAILS_ERROR:
      return {
        ...state,
      };
    case types.ORDER_CONFIRM_REQUEST:
      return {
        ...state,
        error: undefined,
      };
    case types.ORDER_CONFIRM_SUCCESS:
      return {
        ...state,
        // checkoutDetails: null,
        error: undefined,
      };
    case types.ORDER_CONFIRM_ERROR:
      return {
        ...state,
        // error: action.data.errorMessage,
      };
    default:
      return state;
  }
};

export const actions = {
  getOrderCount: (filters?: any) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.ORDER_STATUS_REQUEST });
    try {
      const response = await api.order.getOrderCount(filters);
      const { data } = response;
      dispatch({ type: types.ORDER_STATUS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.ORDER_STATUS_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },
  getOrderList: (page: number, filter?: any) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.GET_ORDER_REQUEST });
    try {
      const response = await api.order.getOrderList(page, filter);
      const { data } = response;
      dispatch({ type: types.GET_ORDER_SUCCESS, data, page });
    } catch (error) {
      dispatch({ type: types.GET_ORDER_ERROR });
      throw error;
    }
  },

  getOrderDetails: (orderID: number) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.GET_ORDER_DETAILS_REQUEST });
    try {
      const response = await api.order.getOrderDetails(orderID);
      const { data } = response;
      dispatch({ type: types.GET_ORDER_DETAILS_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.GET_ORDER_DETAILS_ERROR });
      throw error;
    }
  },

  changeOrderStatus: (
    orderID: number,
    status: string,
    reason?: string,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.ORDER_UPDATE_REQUEST });
    try {
      const response = await api.order.changeOrderStatus(
        orderID,
        status,
        reason
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        if (onError) onError();
      } else {
        dispatch({
          type: types.ORDER_UPDATE_SUCCESS,
          data,
        });
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.ORDER_UPDATE_ERROR,
        data: { errorMessage: 'Unable to update status' },
      });
      if (onError) onError();
    }
  },

  highOrderRequest: (
    productID: number,
    productOptionID: number,
    orderQty: number,
    onSuccess?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.HIGHT_ORDER_REQUEST });
    try {
      const response = await api.order.highOrderRequest(
        productID,
        productOptionID,
        orderQty
      );
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
      } else {
        dispatch({
          type: types.HIGHT_ORDER_SUCCESS,
          data,
        });
        toastr.success('Success', 'Request posted successfully');
        if (onSuccess) onSuccess();
      }
    } catch (error) {
      dispatch({
        type: types.HIGHT_ORDER_ERROR,
        data: { errorMessage: 'Unable to join' },
      });
    }
  },
  getOrderFilter: () => async (dispatch: Dispatch, getState: () => any) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.ORDER_FILTER_REQUEST });
    try {
      const response = await api.order.getOrderFilter();
      const { data } = response;
      dispatch({ type: types.ORDER_FILTER_SUCCESS, data });
    } catch (error) {
      dispatch({ type: types.ORDER_STATUS_ERROR });
      // toastr.error('Error', 'Error Fetching profile');
      throw error;
    }
  },

  downloadOrder: (orderID: number, onSuccess?: () => void) => async (
    dispatch: Dispatch,
    getState: () => any
  ) => {
    const state = getState();

    if (!state.authUser.authToken) {
      return;
    }
    api.setToken(state.authUser.authToken);
    dispatch({ type: types.DOWNLOAD_ORDER_DETAILS_REQUEST });
    try {
      const response = await api.order.downloadOrder(orderID);
      const { data } = response;
      const link = document.createElement('a');
      link.href = data.orderfile;
      link.setAttribute('download', `order${orderID}.pdf`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      dispatch({ type: types.DOWNLOAD_ORDER_DETAILS_SUCCESS, data });
      if (onSuccess) onSuccess();
    } catch (error) {
      dispatch({ type: types.DOWNLOAD_ORDER_DETAILS_ERROR });
      toastr.success('Error', 'Please try again later');
      if (onSuccess) onSuccess();
      throw error;
    }
  },
  orderConfirm: (
    item: OrderConfirm,
    onSuccess?: () => void,
    onError?: () => void
  ) => async (dispatch: Dispatch) => {
    dispatch({ type: types.ORDER_CONFIRM_REQUEST });
    try {
      const response = await api.order.orderConfirm(item);
      const { data } = response;
      if (data.response === 'Failure') {
        toastr.error('Error', data.errorMsg);
        dispatch({
          type: types.ORDER_CONFIRM_SUCCESS,
          data: { errorMessage: 'Unable to confirm order' },
        });
        if (onError) {
          onError();
        }
      } else {
        dispatch({
          type: types.ORDER_CONFIRM_ERROR,
          data,
        });
        if (onSuccess) {
          onSuccess();
        }
        toastr.success('Success', 'Order confirmed successfully');
      }
    } catch (error) {
      dispatch({
        type: types.ORDER_CONFIRM_ERROR,
        data: { errorMessage: 'Unable to confirm order' },
      });
      toastr.error('Error', 'Unable to confirm order');
      if (onError) {
        onError();
      }
    }
  },
};
