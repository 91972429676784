import { stringify } from 'query-string';
import * as http from './http';
import ApiService from './service';
import User from '../../models/user';
import CompanyUser from '../../models/companyUser';
import {
  CrudDetailsRequestModel,
  CrudListRequestModel,
  CrudRequestModel,
} from '../models';
import ShippingAddress from '../../models/shippingAddress';
import Company from '../../models/company';

const serviceEndpoint = 'user';

export default class UserService extends ApiService {
  public async getList(request: CrudListRequestModel, page: number) {
    const url = `${
      this.apiDomain
    }/${serviceEndpoint}/listusers/website_id/1/subsite_id/1/languageID/1/userToken/${
      this.token
    }/page/${page || 1}`;

    const response = await http.get(url, this.token);
    return { data: response.data };
  }

  public async login(userLogin: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/login`;
    const data = new FormData();
    data.append('userLogin', userLogin);
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async password(userPassword: string, userToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/password`;
    const data = new FormData();
    data.append('userPassword', userPassword);
    data.append('userToken', userToken ? userToken : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async createPassword(newPassword: string, userToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/updatepassword/`;
    const data = new FormData();
    data.append('newPassword', newPassword);
    data.append('userToken', userToken ? userToken : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async submitOtp(otp: string, userToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/verifyOTP`;
    const data = new FormData();
    data.append('otp', otp);
    data.append('userToken', userToken ? userToken : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async acceptTerms(userToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/gettermsofuse`;
    const data = new FormData();
    data.append('acceptTerms', 'Yes');
    data.append('userToken', userToken ? userToken : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async getStudentProfile() {
    const url = `${this.apiDomain}/${serviceEndpoint}/profile/website_id/1/subsite_id/1/languageID/1/userToken/${this.token}`;
    return http.get(url, this.token);
  }

  public async getTerms(userToken?: string) {
    const token = userToken ? userToken : this.token;
    const url = `${this.apiDomain}/${serviceEndpoint}/gettermsofuse/website_id/1/subsite_id/1/languageID/1/userToken/${token}`;
    return http.get(url, token);
  }
  public async getPrivacy(userToken?: string) {
    const token = userToken ? userToken : this.token;
    const url = `${this.apiDomain}/${serviceEndpoint}/getprivacy/website_id/1/subsite_id/1/languageID/1/userToken/${token}`;
    return http.get(url, token);
  }
  public async updateProfile(userData: User) {
    const url = `${this.apiDomain}/${serviceEndpoint}/profile`;
    const data = new FormData();
    data.append('userFirstName', userData.userFirstName);
    data.append('userLastName', userData.userLastName);
    data.append('userEmail', userData.userEmail);
    data.append('userPhone', userData.userPhone);
    data.append('userPhoneCountryCode', userData.userPhoneCountryCode);
    data.append(
      'userPhoneDialCode',
      userData.userPhoneDialCode ? userData.userPhoneDialCode : ''
    );
    if (userData.userProfilePicture) {
      data.append('userProfilePicture', userData.userProfilePicture);
    }
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    data.append('action', 'update');
    return http.postAsFormData(url, data);
  }

  public async companyUserCreate(userData: CompanyUser, userToken: string) {
    const apiLink = userData.userID ? 'edituser' : 'createuser';
    const action = userData.userID ? 'edit' : 'new';
    const url = `${this.apiDomain}/${serviceEndpoint}/${apiLink}`;
    const data = new FormData();
    data.append(
      'userFirstName',
      userData.userFirstName ? userData.userFirstName : ''
    );
    data.append(
      'userLastName',
      userData.userLastName ? userData.userLastName : ''
    );
    data.append('userPhone', userData.userPhone ? userData.userPhone : '');
    data.append(
      'userCountryCode',
      userData.userCountryCode ? userData.userCountryCode : ''
    );
    data.append(
      'userPhoneDialCode',
      userData.userPhoneDialCode ? userData.userPhoneDialCode : ''
    );
    if (userData.userID) {
      data.append('userID', userData.userID.toString());
    }
    data.append(
      'orderPermission',
      userData.orderPermission ? userData.orderPermission.toString() : 'false'
    );
    data.append(
      'userDesignation',
      userData.userDesignation ? userData.userDesignation : ''
    );
    if (userData.userEmail) {
      data.append('userEmail', userData.userEmail ? userData.userEmail : '');
    }
    if (userData.userPassword) {
      data.append(
        'userPassword',
        userData.userPassword ? userData.userPassword : ''
      );
    }

    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    data.append('action', 'update');
    return http.postAsFormData(url, data);
  }
  public async companyUserDelete(userID: number, userToken: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/deleteuser`;
    const data = new FormData();

    data.append('userID', userID.toString());
    data.append('userToken', this.token ? this.token : '');

    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    data.append('action', 'delete');
    return http.postAsFormData(url, data);
  }

  public async shippingAddressList(request: CrudListRequestModel) {
    // ?_sort=name&_order=ASC&_filters={"name": "kumar"}
    const { page = 1, perPage = 10 } = request.pagination;
    const { field, order } = request.sort;
    const query = {
      _filters: JSON.stringify(request.filter),
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${this.apiDomain}/${serviceEndpoint}/shipping/website_id/1/subsite_id/1/languageID/1/userToken/${this.token}`;

    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async shippingAddressCreate(
    userData: ShippingAddress,
    userToken: string
  ) {
    const action = userData.userShippingID ? 'edit' : 'new';
    const url = `${this.apiDomain}/${serviceEndpoint}/shipping`;
    const data = new FormData();
    data.append(
      'userShippingID',
      userData.userShippingID ? userData.userShippingID.toString() : ''
    );
    data.append('userToken', this.token ? this.token : '');
    data.append('shippingFname', userData.shippingFname);
    // data.append('shippingLname', userData.shippingLname);
    data.append('shippingAddress1', userData.shippingAddress1);
    data.append('shippingAddress2', userData.shippingAddress2);
    data.append('shippingCity', userData.shippingCity);
    data.append(
      'shippingCountryID',
      userData.shippingCountryID ? userData.shippingCountryID.toString() : '0'
    );
    data.append('shippingZip', userData.shippingZip);
    data.append('shippingPhone', userData.shippingPhone);
    data.append('shippingPhoneDialCode', userData.shippingPhoneDialCode);
    data.append('shippingPhoneCountryCode', userData.shippingPhoneCountryCode);
    data.append('shippingContactName', userData.shippingContactName);
    data.append('shippingContactPhone', userData.shippingContactPhone);
    data.append(
      'shippingContactPhoneCountryCode',
      userData.shippingContactPhoneCountryCode
    );
    data.append(
      'shippingContactPhoneDialCode',
      userData.shippingContactPhoneDialCode
    );
    data.append('action', action);
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async shippingAddressDelete(
    userShippingID: number,
    userToken: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/shipping`;
    const data = new FormData();

    data.append('userShippingID', userShippingID.toString());
    data.append('userToken', this.token ? this.token : '');
    data.append('action', 'delete');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }
  public async getCompanyProfile() {
    const url = `${this.apiDomain}/${serviceEndpoint}/companyprofile/website_id/1/subsite_id/1/languageID/1/userToken/${this.token}`;
    return http.get(url, this.token);
  }
  public async companyAddressUpdate(userData: Company) {
    const url = `${this.apiDomain}/${serviceEndpoint}/editaddress`;
    const data = new FormData();
    data.append('userID', userData.userID ? userData.userID.toString() : '');
    data.append('userToken', this.token ? this.token : '');

    data.append('userAddress', userData.userAddress || '');
    data.append('userCity', userData.userCity || '');
    data.append('userZip', userData.userZip || '');

    data.append(
      'userCountry',
      userData.userCountry ? userData.userCountry.toString() : '0'
    );
    data.append(
      'countryID',
      userData.userCountry ? userData.userCountry.toString() : '0'
    );
    data.append('billingPhone', userData.billingPhone || '');
    data.append('billingPhoneDialCode', userData.billingPhoneDialCode || '');
    data.append(
      'billingPhoneCountryCode',
      userData.billingPhoneCountryCode || ''
    );
    data.append('action', 'update');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async companyProfileUpdate(userData: Company) {
    const url = `${this.apiDomain}/${serviceEndpoint}/companyprofile`;
    const data = new FormData();
    data.append('userID', userData.userID ? userData.userID.toString() : '');
    data.append('userToken', this.token ? this.token : '');
    data.append('billingName', userData.billingName || '');
    data.append('billingEmail', userData.billingEmail || '');
    data.append(
      'tradeLicenseNo',
      userData.tradeLicenseNo ? userData.tradeLicenseNo.toString() : '0'
    );
    data.append('trn', userData.trn || '');
    data.append('companyLogo', userData.companyLogo || '');
    data.append('action', 'update');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    if (userData.companyLogo) {
      data.append('companyLogo', userData.companyLogo);
    }
    return http.postAsFormData(url, data);
  }

  public async documentList() {
    const url = `${this.apiDomain}/${serviceEndpoint}/userdocuments/website_id/1/subsite_id/1/languageID/1/userToken/${this.token}`;

    const response = await http.get(url, this.token);
    return { data: response.data };
  }
  public async documentDelete(documentID: number) {
    const url = `${this.apiDomain}/${serviceEndpoint}/documentdelete`;
    const data = new FormData();

    data.append('documentID', documentID.toString());
    data.append('userToken', this.token ? this.token : '');
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }
  public async forgotPassword(userLogin: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/forgotPassword`;
    const data = new FormData();
    data.append('userLogin', userLogin);
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }
  public async changePassword(
    otp: string,
    newPassword: string,
    userToken?: string
  ) {
    const url = `${this.apiDomain}/${serviceEndpoint}/changepassword`;
    const data = new FormData();
    data.append('otp', otp);
    data.append('userToken', userToken ? userToken : '');
    data.append('newPassword', newPassword);
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }

  public async resendOTP(userLogin: string, userToken?: string) {
    const url = `${this.apiDomain}/${serviceEndpoint}/resendOTP`;
    const data = new FormData();
    data.append('userToken', userToken ? userToken : '');
    data.append('userLogin', userLogin);
    data.append('website_id', '1');
    data.append('subsite_id', '1');
    data.append('languageID', '1');
    return http.postAsFormData(url, data);
  }
}
